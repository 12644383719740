import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";
import { Fragment } from "react/jsx-runtime";
import { MainButton } from "../shared/MainButton";

type Step = {
  id: number;
  stepTitle: string;
  image: string;
  title: string;
  message: string;
};

export const steps: Step[] = [
  {
    id: 1,
    stepTitle: "Pick a Player",
    image: "/how-to-play/step01.png",
    title: "Fast and easy",
    message: "Choose a better player",
  },
  {
    id: 2,
    stepTitle: "Get points from real-world\nperformance",
    image: "/how-to-play/step02.png",
    title: "Player Performance",
    message: "measured by goals, assists, passes,\nand more",
  },
  {
    id: 3,
    stepTitle: "Play tournaments",
    image: "/how-to-play/step03.png",
    title: "Players Worldwide",
    message: "compete with other players",
  },
  {
    id: 4,
    stepTitle: "become a star",
    image: "/how-to-play/step04.png",
    title: "Win Prizes",
    message: "Multiply your money",
  },
];

const TextComponent = ({ text }: { text: string }) => (
  <>
    {text.split("\n").map((line) => (
      <Fragment key={line}>
        {line}
        <br />
      </Fragment>
    ))}
  </>
);

export const StepComponent = ({ step, className }: { step: Step; className?: string }) => (
  <div
    className={cn("bg-[#0B0B0B] text-center flex flex-col justify-between items-center", className)}
    style={{ animationDelay: `${0.2 * step.id}s` }}
  >
    <p className="text-[#B2B2B2] text-[14px] leading-[14px]">{step.id}</p>

    <p className="text-white text-[24px] leading-[22px] h-8 md:h-14 mt-[4px]">
      <TextComponent text={step.stepTitle} />
    </p>

    <img src={step.image} alt={step.stepTitle} className="mt-5 w-5/6 md:w-full md:mt-3" draggable={false} />

    <div className="flex-1 flex flex-col justify-start mt-6">
      <p className="text-white text-[24px] leading-[24px]">{step.title}</p>

      <p className="text-[#B2B2B2] text-[14px] mt-[10px] leading-[20px]">
        <TextComponent text={step.message} />
      </p>
    </div>
  </div>
);

export const HowToPlay = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <div className="w-full h-full text-center max-w-[1440px] px-[30px] mx-auto mt-[80px]">
        <p className={cn("text-[#B2B2B2] text-[14px] leading-[14px] fadeInUp", inView && "fadeInUp-active")}>
          How To Plays
        </p>
        <p
          className={cn("text-white text-[32px] leading-[40px] fadeInUp", inView && "fadeInUp-active")}
          style={{ animationDelay: "0.1s" }}
        >
          What is thumba?
        </p>
      </div>

      <div className="w-full max-w-[1440px] mx-auto flex justify-between gap-4 my-[40px]">
        {steps.map((step) => (
          <StepComponent
            key={step.id}
            step={step}
            className={cn(
              "border border-[#313131] px-6 py-[40px] h-[400px] fadeInUp [max-width:calc(25%)]",
              inView && "fadeInUp-active",
            )}
          />
        ))}
      </div>

      <div className="w-full h-full text-center max-w-[1360px] mx-auto">
        <a
          href="https://t.me/ThumbaGameBot"
          className={cn("fadeInUp", inView && "fadeInUp-active")}
          style={{ animationDelay: "1s" }}
        >
          <MainButton width="100%" className="max-w-[1280px] w-4/5 mx-auto text-[30px]">
            Play Now
          </MainButton>
        </a>
      </div>
    </div>
  );
};
